<template>
  <div
    class="w-full max-w-7xl mx-auto flex flex-row items-center justify-between gap-3 py-3 px-3 xl:px-0"
  >
    <div class="w-full">
      <NuxtLink to="/">
        <img :src="logoUrl" alt="Logo" class="w-full max-w-[175px]" />
      </NuxtLink>
    </div>

    <div
      class="w-full flex flex-row items-center justify-end sm:justify-end gap-3"
    >
      <NuxtLink class="link" :to="joinUrl">Movies</NuxtLink>
      <a v-if="!atRoot" class="link" href="/tours/top-rated">Top Rated</a>
      <NuxtLink class="link" to="/models">Models</NuxtLink>
      <NuxtLink v-if="!atRoot" class="link" to="/series">Series</NuxtLink>

      <UButton
        v-if="!inMembersArea"
        color="white"
        variant="outline"
        class="uppercase text-lg text-white border-4 rounded-none"
        to="/login"
      >
        Login
      </UButton>

      <UButton
        v-if="!inMembersArea"
        variant="outline"
        color="green"
        class="uppercase text-lg text-white border-4 border-green-400 rounded-none"
        :to="joinUrl"
      >
        Join Now
      </UButton>
    </div>
  </div>
</template>

<script setup>
  const route = useRoute()
  const atRoot = route.path === "/"
  const currentSite = inject("currentSite")
  const logoUrl = `/images/sites/${currentSite.value.slug}/logo.png`
  const joinUrl = currentSite.value.natsJoinUrl
  const { inMembersArea } = useMembersArea()
</script>

<style scoped>
  .link {
    @apply hidden sm:block text-xl text-white hover:text-gray-400 font-semibold tracking-tight whitespace-nowrap;
  }
</style>
