import LayoutClassicTour from "@/components/Layout/ClassicTour.vue"
import LayoutPlusTour from "@/components/Layout/PlusTour.vue"

export const useSiteSettings = (site) => {
  const sitesWithVerticalCards = ["creepypa"]
  const useVerticalCards = sitesWithVerticalCards.includes(site.slug)

  const sitesWithVerticalActorCards = ["pornplus"]
  const useVerticalActorCards = sitesWithVerticalActorCards.includes(site.slug)

  const sitesWithExclusiveSeries = ["gayroom", "pornplus", "pornpros"]
  const hasExclusiveSeries = sitesWithExclusiveSeries.includes(site.slug)

  const notFoundUrl = site.notFoundUrl

  const siteHasVRVideos = site.slug === "wetvr"

  const siteIcon = (site) => {
    return `/images/sites/${site.slug.replace("-", "")}/logo.png`
  }

  const siteNameToColoredText = (siteSlug) => {
    const colors = {
      anal4k:
        "<span class='font-black text-pink-600'>Anal</span><span class='font-black text-white'>4K</span>",
      baeb: "<span class='text-white font-black'>Baeb</span>",
      bathhousebait:
        "<span class='text-blue-400 font-black'>Bath</span><span class='text-blue-300'>House</span><span class='text-white'>Bait</span>",
      bbcpie:
        "<span class='text-[#C70200] font-black'>BBC</span><span class='text-white font-black'>Pie</span>",
      boysdestroyed:
        "<span class='text-amber-400 font-black'>Boys</span><span class='text-amber-700'>Destroyed</span>",
      castingcouchx:
        "<span class='font-black text-pink-600'>CastingCouch</span><span class='font-black text-white'>X</span>",
      creepypa:
        "<span class='font-black text-red-800'>Creepy</span><span class='font-black text-white'>PA</span>",
      cum4k:
        "<span class='text-[#f85079] font-black'>Cum</span><span class='text-white font-black'>4K</span>",
      damnthatsbig:
        "<span class='text-pink-400 font-black'>Damn</span><span class='text-white'>Thats</span><span class='text-purple-300 font-black'>Big</span>",
      exotic4k:
        "<span class='font-black text-green-600'>Exotic</span><span class='font-black text-white'>4K</span>",
      facials4k:
        "<span class='font-black text-green-600'>Facials</span><span class='font-black text-white'>4K</span>",
      fantasyhd:
        "<span class='font-black text-blue-600'>Fantasy</span><span class='font-black text-white'>HD</span>",
      gaycastings:
        "<span class='text-yellow-300 font-black'>Gay</span><span class='text-white'>Castings</span>",
      gaycreeps:
        "<span class='text-red-600 font-black'>Gay</span><span class='text-blue-400'>Creeps</span>",
      gayroom:
        "<span class='text-yellow-400 font-black'>Gay</span><span class='text-white font-black'>Room</span>",
      gayviolations:
        "<span class='text-red-700 font-black'>Gay</span><span class='text-white font-black'>Violations</span>",
      girlcum:
        "<span class='text-[#db5186] font-black'>Girl</span><span class='text-white font-black'>Cum</span>",
      holed: "<span class='text-[#db4490] font-black'>Holed</span>",
      lubed: "<span class='text-[#00d3ff] font-black'>Lubed</span>",
      manroyale:
        "<span class='text-blue-100 font-black'>Man</span><span class='text-blue-600 font-black'>Royale</span>",
      massagebait:
        "<span class='text-red-600 font-black'>Massage</span><span class='text-white font-black'>Bait</span>",
      menpov:
        "<span class='text-red-800 font-black'>Men</span><span class='text-gray-400 font-black'>POV</span>",
      officecock:
        "<span class='text-white font-black'>Office</span><span class='text-gray-200 font-black'>Cock</span>",
      outhim:
        "<span class='text-white font-black'>Out</span><span class='text-yellow-200 font-black'>Him</span>",
      mom4k: "<span class='font-black text-blue-300'>Mom4K</span>",
      momcum: "<span class='font-black text-blue-300'>MomCum</span>",
      myveryfirsttime:
        "<span class='font-black text-blue-300'>My</span><span class='font-black text-yellow-300'>Very</span><span class='font-black text-red-300'>First</span><span class='font-black text-green-300'>Time</span>",
      nannyspy: "<span class='font-black text-violet-400'>NannySpy</span>",
      passionhd:
        "<span class='text-[#f56ed5] font-black'>Passion</span><span class='text-white font-black'>HD</span>",
      pornplus:
        "<span class='text-[#999999] font-black'>Porn</span><span class='text-white font-black'>+</span>",
      pornpros:
        "<span class='text-red-600 font-black'>Porn</span><span class='text-white font-black'>Pros</span>",
      pornprosnetwork:
        "<span class='text-red-600 font-black'>PornPros</span><span class='text-white font-black'>Network</span>",
      povd: "<span class='font-black text-purple-600'>POVD</span>",
      puremature:
        "<span class='text-[#c56df8] font-black'>Pure</span><span class='text-white font-black'>Mature</span>",
      showerbait:
        "<span class='text-white font-black'>Shower</span><span class='text-blue-200 font-black'>Bait</span>",
      spyfam:
        "<span class='text-[#ff0000] font-black'>Spy</span><span class='text-white font-black'>Fam</span>",
      strippers4k:
        "<span class='text-pink-700 font-black'>Strippers</span><span class='text-white font-black'>4K</span>",
      thickandbig:
        "<span class='text-gray-100 font-black'>Thick</span><span class='text-white font-black'>And</span><span class='text-gray-400 font-black'>Big</span>",
      tiny4k:
        "<span class='text-[#18d3ff] font-black'>Tiny</span><span class='text-white font-black'>4K</span>",
      wetvr:
        "<span class='text-blue-300 font-black'>Wet</span><span class='text-white font-black'>VR</span>",
    }

    if (colors[siteSlug.replace("-", "")]) {
      return colors[siteSlug.replace("-", "")]
    } else {
      return `<span class='text-red-600 font-black'>FILL</span><span class='text-white font-black'>${siteSlug}</span>`
    }
  }

  const sfwTourIndexImageCount = ["creepypa"].includes(site.slug) ? 8 : 16

  // TODO: These old short names suck. But where else does this go?
  const shortNameToLongNameMapping = (shortName) => {
    switch (shortName.toLowerCase()) {
      case "bhouse":
        return "bathhousebait"
      case "byd":
        return "boysdestroyed"
      case "damn":
        return "damnthatsbig"
      case "gcr":
        return "gaycreeps"
      case "gvs":
        return "gayviolations"
      case "msb":
        return "massagebait"
      case "ock":
        return "officecock"
      case "ohm":
        return "outhim"
      case "tnb":
        return "thickandbig"
      default:
        return shortName
    }
  }

  const networks = {
    pornpros: [
      "18yearsold",
      "40ozbounce",
      "amateurviolations",
      "cockcompetition",
      "crueltyparty",
      "cumdisgrace",
      "cumshotsurprise",
      "deepthroatlove",
      "disgraced18",
      "eurohumpers",
      "flexiblepositions",
      "freaksofboobs",
      "freaksofcock",
      "jurassiccock",
      "lolcumshots",
      "massagecreep",
      "milfhumiliation",
      "pimpparade",
      "pornpros",
      "publicviolations",
      "realexgirlfriends",
      "shadypi",
      "squirtdisgrace",
      "teenbff",
      "webcamhackers",
    ],
    gayroom: [
      "bathhousebait",
      "boysdestroyed",
      "damnthatsbig",
      "gaycastings",
      "gaycreeps",
      "gayroom",
      "gayviolations",
      "manroyale",
      "massagebait",
      "menpov",
      "officecock",
      "outhim",
      "showerbait",
      "thickandbig",
    ],
    exgfpass: [
      "exgf",
      "jizzgfs",
      "miluploadsgfs",
      "sisterreunion",
      "suicidegfs",
      "teengfs",
    ],
  }

  const sponsorLogo = (sponsor) => {
    if (site.slug == "pornplus") {
      if (sponsor.hasImages && networks.pornpros.includes(site.slug)) {
        // Is there a better way to do this?
        return "https://cdn-images.r1.cdn.pornpros.com/content/sponsors/000/000/582/logo_small.png?imgh=16"
      } else {
        return sponsor.logoUrl
      }
    }
  }

  const useTourLayout = computed(() => {
    if (site.slug === "pornplus") {
      return markRaw(LayoutPlusTour)
    }
    return markRaw(LayoutClassicTour)
  })

  return {
    hasExclusiveSeries,
    notFoundUrl,
    sfwTourIndexImageCount,
    shortNameToLongNameMapping,
    sponsorLogo,
    siteHasVRVideos,
    siteIcon,
    siteNameToColoredText,
    useTourLayout,
    useVerticalActorCards,
    useVerticalCards,
  }
}
